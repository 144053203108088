<template>
    <div class='tournament-page'>
        <h4 class='page-title'>{{ getActionName }}</h4>
        <div class='widget'>
            <FormBuilder
                class='row'
                v-if='pageType === "edit" ? pageData : true'
                :form-data='formData'
                :save='saveDataFunc'
            />
        </div>
    </div>
</template>

<script>
import FormBuilder from '../../components/FormBuilder/FormBuilder';
export default {
    name: 'TournamentsDetail',
    components: { FormBuilder },
    data() {
        return {
            pageType: null,
            pageData: null,
            saveDataFunc: null,
            formData: {
                'title': {
                    'type': 'text',
                    'label': 'Название',
                    'validation': 'required|max:120',
                    'value': null,
                },
                'title_en': {
                    'type': 'text',
                    'label': 'Название англ',
                    'validation': 'max:120',
                    'value': null,
                },
                'tournaments': {
                    'type': 'tabs',
                    'value': null,
                }
            },
        };
    },
    computed: {
        getActionName() {
            if (this.pageType === 'create') {
                return 'Создать турнир';
            }

            if (this.pageType === 'edit') {
                return 'Редактировать турнир';
            }
            return '';
        },
    },
    methods: {
        extractPageType() {
            const path = this.$route.path.split('/');
            this.pageType = path[path.length - 1];
        },
        async saveNewData(formData) {
            try {
                await this.$axios.post('api/tournament', formData);
                this.$toasted.success(`Турнир ${formData.title} создан!`, {position: "bottom-right"});
            } catch (e) {
                this.handleAxiosError(e);
            }
        },
        handleAxiosError(error) {
            const { data } = error.response;
            if (data.error) {
                this.$toasted.error(data.message, {position: 'bottom-right'});
                return;
            }
            if (data.errors) {
                this.$toasted.error(data.message, {position: 'bottom-right'});
                return;
            }
        },
        async saveEditData(formData) {
            try {
                const id = this.$route.params.id;
                await this.$axios.post('api/tournament/' + id, formData);
                this.$toasted.success(`Турнир ${formData.title} отредактирован!`, {position: "bottom-right"});
            } catch (e) {
                this.handleAxiosError(e);
            }
        },
        fillFormData(data) {
            this.formData.title.value = data.title;
            this.formData.title_en.value = data.title_en;
            if (!this.formData.tournaments.value) {
                this.formData.tournaments.value = {}
            }

            for (const key in data) {
                if (key.indexOf('tournament') !== -1) {
                    this.fillFormDataTournament(data, key)
                }
            }
        },
        fillFormDataTournament(data, key) {
            const spilt = key.split('_');
            const tournamentId = 'tournament_' + spilt[1];
            const leagueId = 'league_' + spilt[2];

            if (!this.formData.tournaments.value[tournamentId]) {
                this.formData.tournaments.value[tournamentId] = {
                    title: `Турнир ${spilt[1]}`,
                    value: {},
                }
            }

            this.formData.tournaments.value[tournamentId].value[leagueId] = {
                'id': key,
                'type': 'text',
                'label': `Ссылка на турнир ${spilt[2]}-ой лиги`,
                'validation': 'max:120',
                'value': data[key],
            };
        },
        setInitTournamentTabs() {
            if (!this.formData.tournaments.value) {
                this.formData.tournaments.value = {}
            }

            for (let tournamentKey = 1; tournamentKey < 6; tournamentKey++) {
                const tournamentId = 'tournament_' + tournamentKey;

                if (!this.formData.tournaments.value[tournamentId]) {
                    this.formData.tournaments.value[tournamentId] = {
                        title: `Турнир ${tournamentKey}`,
                        value: {},
                    }
                }
                for (let leagueKey = 1; leagueKey < 5; leagueKey++) {
                    const leagueId = 'league_' + leagueKey;
                    const key = tournamentId + `_${leagueKey}_url`;

                    this.formData.tournaments.value[tournamentId].value[leagueId] = {
                        'id': key,
                        'type': 'text',
                        'label': `Ссылка на турнир ${leagueKey}-ой лиги`,
                        'validation': 'max:120',
                        'value': null,
                    };
                }
            }
        },
        async fetchEditData() {
            const id = this.$route.params.id;
            if (!id) {
                this.$toasted.error("Нельзя редактировать без ID", {position: "bottom-right"});
                return;
            }
            try {
                const {data} = await this.$axios.get('/api/tournament/' + id);
                if (data && data.data) {
                    this.fillFormData(data.data);
                    this.pageData = data.data;
                }
            } catch (e) {
                alert(e);
            }
        }
    },
    async created() {
        this.extractPageType();
        if (this.pageType === 'edit') {
            await this.fetchEditData();
            this.saveDataFunc = this.saveEditData;
        }
        if (this.pageType === 'create') {
            this.setInitTournamentTabs();
            this.saveDataFunc = this.saveNewData;
        }
    },
};
</script>

<style scoped>

</style>
